import { Auth } from 'aws-amplify';
import { API_URL, INSPECTION_SERVER_URL } from '../../config';
import noPicturePlaceholder from '../../img/no_picture_placeholder.png';
import {
  Data,
  DataInstance,
  DataObject,
  Machine,
  Media,
  SinglePart,
  User,
} from './types.inspections';
import { SpecStatus } from './show/pdf/type.pdf';

const configsByType = (data: Data, type: 'images' | 'damages') =>
  Object.values(data)
    .filter((element: DataInstance) => element[type])
    .map((element: DataInstance) => element[type])
    .flat();

export const getInspectionMainImage = (inspection: DataObject) =>
  `${INSPECTION_SERVER_URL}${inspection.id}/${inspection.ref}.jpg`;

export const getAllImages = (inspection: DataObject) =>
  inspection?.data
    ? configsByType(inspection.data, 'images')
        .concat(configsByType(inspection.data, 'damages'))
        .map(
          (element: Media) =>
            `${INSPECTION_SERVER_URL}${inspection.id}/${element.ref}.jpg`,
        )
    : [noPicturePlaceholder];

export const requestQuote = async ({
  parts,
  id,
  setQuoteResponse,
}: {
  parts: SinglePart[];
  id: number;
  setQuoteResponse?: (argument: any) => void;
}) => {
  const token = (await Auth.currentSession()).getIdToken().getJwtToken();
  const requester_email = (await Auth.currentAuthenticatedUser()).attributes
    .email;

  const body = JSON.stringify({
    requester_email,
    parts,
  });
  const response = await fetch(`${API_URL}/inspections/${id}/quote`, {
    method: 'PUT',
    body,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (setQuoteResponse) {
    setQuoteResponse(response);
  }
  return response;
};

export const checkQuotePossible = async ({
  id,
  setQuotePossibleResponse,
}: {
  id: number;
  setQuotePossibleResponse?: (argument: any) => void;
}) => {
  const token = (await Auth.currentSession()).getIdToken().getJwtToken();

  const response = await fetch(`${API_URL}/inspections/${id}/quote`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (setQuotePossibleResponse) {
    setQuotePossibleResponse(response);
  }
  return response;
};

export const machinesQuery = {
  type: 'getList',
  resource: 'machines',
  payload: {
    pagination: { page: 1, perPage: 500000000 },
    sort: {},
    filter: {},
  },
};

export const usersQuery = {
  type: 'getList',
  resource: 'users',
  payload: {
    pagination: { page: 1, perPage: 500000000 },
    sort: {},
    filter: {},
  },
};

export const machineByIdQuery = (id: number) => ({
  type: 'getOne',
  resource: 'machines',
  payload: { id },
});

export const createMachineChoices = (machines: Machine[]) =>
  machines?.map(({ id, manufacturer, model, category }: Machine) => ({
    id,
    name: `No. ${id}: ${manufacturer} - ${model} - ${category}`,
  })) ?? [];

export const createUserChoices = (users: User[]) =>
  users?.map(({ id, email }: User) => ({
    id,
    name: email,
  })) ?? [];

export const getStatusInfo = (
  data: DataInstance,
): { statusText: string; statusClass: string; specStatus: SpecStatus } => {
  if (data?.not_available && data?.damages) {
    return {
      statusText: 'specification.no_damage',
      statusClass: 'status-in-order',
      specStatus: 'OKAY',
    };
  }

  if (data?.not_available) {
    return {
      statusText: 'specification.not_available',
      statusClass: 'status-not-available',
      specStatus: 'NOT_AVAILABLE',
    };
  }

  if (data?.rating) {
    return {
      statusText: `${data.rating}/5`,
      statusClass: 'status-in-order',
      specStatus: 'OKAY',
    };
  }

  if (data?.parts?.length) {
    return {
      statusText: 'specification.parts_needed',
      statusClass: 'status-in-order',
      specStatus: 'OKAY',
    };
  }

  if (data?.free_input && data?.free_input.every((text) => !!text)) {
    return {
      statusText: 'specification.okay',
      statusClass: 'status-in-order',
      specStatus: 'OKAY',
    };
  }

  if (
    data?.status === 1 ||
    data?.parts ||
    data?.checkboxes ||
    data?.qr_codes ||
    (data?.status !== 0 &&
      !data?.status &&
      (data?.images?.length || data?.videos?.length) &&
      !data?.damages)
  ) {
    return {
      statusText: 'specification.okay',
      statusClass: 'status-in-order',
      specStatus: 'OKAY',
    };
  }

  if (data?.status === 2 || data?.damages?.length) {
    return {
      statusText: 'specification.not_okay',
      statusClass: 'status-not-in-order',
      specStatus: 'NOT_OKAY',
    };
  }

  return {
    statusText: 'specification.not_complete',
    statusClass: 'status-not-complete',
    specStatus: 'NOT_CHECKED',
  };
};
