import { Text } from '@react-pdf/renderer';
import React from 'react';

export const PdfFreeInput = ({ freeInputPairs }: { freeInputPairs?:{title: string, value: string}[] | null }) =>(
  <>
     {freeInputPairs.map((freeInputPair) => (
      <Text>
        {'\u00A0'}{freeInputPair.title} : {freeInputPair.value}
      </Text>
    ))}
  </>
);