import React from 'react';
import { Text, View, Image } from '@react-pdf/renderer';
import { styles } from '../styles';

interface PdfHeaderProps {
  header: string;
  subheader?: string;
  image?: string;
  backgroundColor?: string;
}
export const PdfHeader = ({
  header,
  subheader,
  image,
  backgroundColor,
}: PdfHeaderProps) => (
  <View style={[styles.headerContainer, { backgroundColor: backgroundColor }]}>
    <View style={styles.header}>
      <Text style={styles.headerTitle}>{header}</Text>
      <Text style={styles.headerSubtitle}>{subheader}</Text>
    </View>
    {image ? <Image style={styles.logotype} src={image} /> : null}
  </View>
);
