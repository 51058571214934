import { Link, Text } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../styles';
import { StatusInfo } from '../../type.pdf';

export const PdfStatusInfo = ({ statusInfo }: { statusInfo: StatusInfo[] }) => {
  return (
    <>
      {statusInfo.map(({ noteType, noteValue }: StatusInfo) =>
        noteType === 'Url' ? (
          <Text style={styles.commentText}>
            - {noteType}: <Link src={noteValue}>{noteValue}</Link>
          </Text>
        ) : (
          <Text style={styles.commentText}>
            - {noteType}: {noteValue}
          </Text>
        ),
      )}
    </>
  );
};
