import { View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../styles';
import { FormattedImages, PdfImageWithTextProps } from '../../type.pdf';
import { PdfImageWithText } from './image-with-text';

export const PdfImages = (props: { images: FormattedImages }) => {
  const { images: groupedImages } = props;
  return (
    <>
      {groupedImages.map(
        (imageGroup: PdfImageWithTextProps[], groupIndex: number) => (
          <View key={`image-group ${groupIndex}`} style={styles.imageRow}>
            {imageGroup.map(
              (imageProps: PdfImageWithTextProps, imageIndex: number) => (
                <PdfImageWithText
                  key={`image-${groupIndex}${imageIndex}`}
                  {...imageProps}
                />
              ),
            )}
          </View>
        ),
      )}
    </>
  );
};
