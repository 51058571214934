import ImageResize from 'image-resize';
import { INSPECTION_SERVER_URL } from '../../../../config';
import {
  Data,
  DataInstance,
  DataObject,
  ImageConfig,
  InspectionTemplate,
  SpecificationInt,
} from '../../types.inspections';
import {
  FormatSpecProps,
  FormattedSpecData,
  GroupImages,
  ImageResizingOptions,
  FormatPdfProps,
  PdfImageWithTextProps,
  SpecStatus,
  TitlesArray,
  RecursivelyFormattedSpecData,
} from './type.pdf';
import { getImageSize } from 'react-image-size';
import { getStatusInfo } from 'pages/inspections/utils';

const refToSrc = (ref: string, id: number) => {
  const method = 'GET' as const;
  const uri: string = ref
    ? `${INSPECTION_SERVER_URL}${id}/${ref}.jpg?.time=${Date.now()}`
    : '';
  const body: string = '';
  const headers: string = '';

  return { uri, method, body, headers };
};

const returnStatus = (
  data: DataInstance,
): { status: string; specStatus: SpecStatus } => {
  const { statusText, specStatus } = getStatusInfo(data);
  return { status: statusText, specStatus };
};

export const formatPdfSpecRecord = async ({
  slug,
  label,
  type,
  config,
  data,
  translate,
  displayImages,
  parentSlug,
  parentLabel,
  id,
  imageResizingOptions,
}: FormatSpecProps): Promise<FormattedSpecData> => {
  const fullSlug: string = parentSlug ? `${parentSlug}.${slug}` : slug;

  const itemData = data?.[fullSlug];
  const { status, specStatus } = returnStatus(itemData);
  const displayStatusInfo: boolean = Boolean(
    status === 'specification.not_okay',
  );

  const header: string = parentLabel
    ? `${parentLabel} - ${label}: ${translate(`config.${type}`)}`
    : `${label}: ${translate(`config.${type}`)}`;

  const {
    images,
    damages,
    videos,
    error_codes,
    comment,
    parts,
    checkboxes,
    qr_codes,
    rating,
    free_input,
  }: DataInstance = itemData ?? ({} as DataInstance);

  const titles: TitlesArray = config?.images?.map(
    (configItem: ImageConfig) => configItem.title,
  );
  const subtitles: TitlesArray = config?.images?.map(
    (configItem: ImageConfig) => configItem.subtitle,
  );

  const [groupedImages, groupedDamages, groupedVideos] = displayImages
    ? await Promise.all([
        groupImages({
          data: images ?? [],
          titles,
          subtitles,
          id,
          imageResizingOptions,
        }),
        groupImages({
          data: damages ?? [],
          titles: undefined,
          subtitles: undefined,
          id,
          imageResizingOptions,
        }),
        groupImages({
          data: videos ?? [],
          titles: undefined,
          subtitles: undefined,
          id,
          imageResizingOptions,
        }),
      ])
    : [null, null, null];

  const statusInfo = displayStatusInfo
    ? config?.status?.map((statusNote) => {
        const [noteType, noteValue]: [string, unknown] =
          Object.entries(statusNote)[0];
        return {
          noteType: translate(`status.${noteType}`),
          noteValue: noteValue as string,
        };
      })
    : undefined;

  const translatedParts =
    parts?.map(({ number, description, quantity }) => ({
      number: `${translate(`parts.number`)} : ${number}`,
      quantity: `${translate(`parts.quantity`)} : ${quantity}`,
      description: `${translate(`parts.description`)} : ${description}`,
    })) ?? [];

  const freeInputPairs = config?.free_input?.map((title, index) => ({
    title,
    value: free_input?.[index] || '-',
  }));

  return {
    text: {
      header,
      status: translate(status),
      errors: error_codes ?? [],
      comment: comment === '' ? undefined : comment,
      statusInfo,
      parts: translatedParts,
      checkboxes: checkboxes ?? undefined,
      qrCodes: qr_codes,
      specStatus: specStatus,
      rating: rating ? `${rating}/5` : undefined,
      freeInputPairs: freeInputPairs,
    },
    images: groupedImages,
    damages: groupedDamages,
    videos: groupedVideos,
  };
};

const groupImages = async ({
  data,
  titles,
  subtitles,
  id,
  imageResizingOptions,
}: GroupImages): Promise<PdfImageWithTextProps[][]> => {
  const groupLimit: number = 4;
  let arrayIndex: number = 0;
  let groupTally: number = 0;
  const groupedImages: PdfImageWithTextProps[][] = [[]];

  for (let imageIndex = 0; imageIndex < data.length; imageIndex++) {
    const { comment, type, ref } = data[imageIndex];
    const title = titles?.[imageIndex];
    const subtitle = subtitles?.[imageIndex];
    const groupAddition = Boolean(title || subtitle || comment || type) ? 2 : 1;
    const src = refToSrc(ref, id);
    const resizedSrc = {
      ...src,
      uri: await resizeImage(src.uri, imageResizingOptions),
    };

    const imageDimensions = await getImageSize(src.uri);

    const singleImageProps: PdfImageWithTextProps = {
      src: resizedSrc,
      dimensions: imageDimensions,
      title,
      subtitle,
      comment,
      type,
    };
    if (groupTally + groupAddition <= groupLimit) {
      groupTally += groupAddition;
      groupedImages[arrayIndex].push(singleImageProps);
    } else {
      groupTally = 0;
      arrayIndex += 1;
      groupedImages[arrayIndex] = [singleImageProps];
    }
  }

  return groupedImages;
};

const cumulativeScore = (data: Data) => {
  let score = { actual: 0, potential: 0 };
  if (data) {
    Object.keys(data).map((element) => {
      score.actual += data?.[element]?.rating ?? 0;
      score.potential += data?.[element]?.rating ? 5 : 0;
      return 0;
    });
  }
  return score;
};

export const createHeadData = (
  data: DataObject,
  template: InspectionTemplate,
) => {
  const score = cumulativeScore(data?.data);
  const scoreObject = [
    {
      label: 'cumulative_score',
      value: `${score.actual} /${score.potential}`,
    },
  ];
  const headData = [
    { label: 'inspection_id', value: `${data?.id}` },
    { label: 'machine_id', value: `${data?.machine_id}` },
    { label: 'template_name', value: `${template?.name}` },
    { label: 'template_id', value: `${template?.id}` },
  ];

  return score.potential ? headData.concat(scoreObject) : headData;
};

export const resizeImage = async (
  url: string,
  options: ImageResizingOptions,
) => {
  const { maxHeight, maxWidth } = options;
  const resizer = new ImageResize({
    format: 'jpg',
    width: maxWidth,
    height: maxHeight,
  });

  return (await resizer.play(url)) as string;
};

export const formatPdfRecursively = async ({
  dataObject,
  displayImages,
  translate,
  parentSlug,
  parentSpec,
  parentLabel,
  imageResizingOptions,
}: FormatPdfProps): Promise<RecursivelyFormattedSpecData[]> => {
  if (!parentSpec) return [null];
  return await Promise.all(
    parentSpec
      .filter((spec) => spec.type !== 'tutorial')
      .map(async ({ spec, slug, label, type, config }: SpecificationInt) => {
        const continueRecursion = Boolean(spec?.length);
        return continueRecursion
          ? formatPdfRecursively({
              dataObject,
              displayImages,
              translate,
              parentSpec: spec,
              parentSlug: slug,
              parentLabel: label,
              imageResizingOptions,
            })
          : formatPdfSpecRecord({
              slug,
              label,
              type,
              config,
              parentSlug,
              data: dataObject.data,
              translate,
              displayImages,
              parentLabel,
              id: dataObject.id,
              imageResizingOptions,
            });
      }),
  );
};
