import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  dataTableWhite: {
    borderBottom: '1px solid black',
    color: 'black',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 10,
    padding: 10,
    width: '100%',
  },
  dataTableGrey: {
    backgroundColor: 'lightgrey',
    color: 'black',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '1px solid black',
    fontSize: 10,
    padding: 10,
    width: '100%',
  },
  dataHeader: {
    fontSize: 12,
  },
  dataSubheader: {
    fontSize: 10,
  },
  header: {
    paddingLeft: 10,
    paddingTop: 10,
  },
  headerTitle: {
    fontSize: 20,
  },
  headerSubtitle: {
    fontSize: 14,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    backgroundColor: '#C33',
    height: 70,
  },
  imageContainer: {
    width: '100%',
    padding: '5px',
    flexDirection: 'column',
  },
  imageRow: {
    width: '100%',
    flexDirection: 'row',
  },
  inspectionText: {
    width: '100%',
  },
  logotype: {
    height: '101%',
  },
  page: {
    flexDirection: 'column',
    backgroundColor: 'white',
    fontFamily: 'Oswald',
  },
  pageNumber: {
    position: 'absolute',
    bottom: 10,
    paddingLeft: 290,
    fontSize: 12,
  },
  resultsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
  },
  section: {
    margin: 10,
    paddingHorizontal: 10,
  },
  specificationContainer: {
    marginBottom: '12px',
  },
  resultsContainer: {
    margin: 20,
    color: 'black',
    border: '0.1px solid black',
    justifyContent: 'space-between',
    fontSize: 10,
  },
  subtitle: {
    fontSize: 12,
  },
  textWrapper: {
    marginLeft: 10,
    marginBottom: 10,
  },
  title: {
    fontSize: 14,
  },
  damageTypes: {
    textTransform: 'capitalize',
  },
  textElement: {
    width: '20%',
    textTransform: 'uppercase',
    display: 'flex',
    textAlign: 'right',
  },
  textHeader: {
    width: '100%',
    textTransform: 'uppercase',
    display: 'flex',
  },
  textResult: {
    width: '100%',
    textAlign: 'right',
    textTransform: 'uppercase',
  },
  textStatus: {
    textAlign: 'right',
    textTransform: 'uppercase',
  },
  statusContainer: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  statusMarkerBase: {
    height: '12px',
    width: '12px',
    borderRadius: '50%',
    marginRight: '5px',
  },
  backgroundGreen: {
    backgroundColor: '#4fd15d',
  },
  backgroundYellow: {
    backgroundColor: '#f8db48',
  },
  backgroundRed: {
    backgroundColor: '#cd1a1a',
  },
  borderGreen: {
    border: '1px solid #4fd15d',
  },
  commentText: {
    padding: 3,
    fontSize: 12,
  },
  errorCodes: { paddingRight: 40, paddingBottom: 20 },
  partPadding: { padding: 5 },
});
