import { Image, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../styles';
import { PdfImageWithTextProps } from '../../type.pdf';
import { PdfImageText } from './image-text';

export const PdfImageWithText = ({
  src,
  comment,
  type,
  title,
  subtitle,
  dimensions,
}: PdfImageWithTextProps) => {
  const hasText = Boolean(comment || type?.length || title || subtitle);
  const isLandscape = dimensions.width > dimensions.height;

  return (
    <View
      style={{
        flexDirection: isLandscape ? 'column' : 'row',
        width: hasText || isLandscape ? 280 : 140,
      }}
    >
      <View key={`${src}-image-container`} style={styles.imageContainer}>
        <Image key={`${src}-image`} src={src} />
      </View>
      {hasText && (
        <PdfImageText
          comment={comment}
          type={type}
          title={title}
          subtitle={subtitle}
        />
      )}
    </View>
  );
};
