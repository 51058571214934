import { View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../styles';
import { FormattedSpecData } from '../../type.pdf';
import { PdfImages } from './images';
import { PdfText } from './text';

export const PdfResults = ({
  text,
  images,
  damages,
  videos,
}: FormattedSpecData) => (
  <View
    style={styles.resultsContainer}
    wrap={false}
    key={`results-container-${text.header}`}
  >
    <PdfText {...text} />
    {images && <PdfImages images={images} />}
    {damages && <PdfImages images={damages} />}
    {videos && <PdfImages images={videos} />}
  </View>
);
