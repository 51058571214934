import { createContext } from 'react';
import { ImageResizingOptions } from '../../type.pdf';

export interface PdfState {
  setState: (prop: PdfState) => void;
  imageResizingOptions: ImageResizingOptions;
  includeImages: boolean;
  renderingState: { loading: boolean; status?: string };
}

export const defaultPdfState: Omit<PdfState, 'setState'> = {
  imageResizingOptions: {
    maxHeight: 512,
    maxWidth: 512,
  },
  includeImages: true,
  renderingState: { loading: false },
};

export const PdfContext = createContext<PdfState>({
  ...defaultPdfState,
  setState: () => {},
});
