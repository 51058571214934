import { Text } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../styles';
import { useTranslate } from 'react-admin';

export const PdfPageNumbers = () => {
  const translate = useTranslate();
  return (
    <Text
      render={({ pageNumber, totalPages }) =>
        `${translate('Page')} ${pageNumber} ${translate('of')} ${totalPages}`
      }
      fixed
      style={styles.pageNumber}
    />
  );
};
