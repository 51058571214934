import { Text } from '@react-pdf/renderer';
import React from 'react';

export const PdfCheckboxes = ({ checkboxes }: { checkboxes: string[] }) => (
  <>
    {checkboxes?.map((checkboxValue: string) => (
      <Text>
        {'\u00A0'} - {checkboxValue}
      </Text>
    ))}
  </>
);
