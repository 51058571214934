import { Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../styles';
import { PdfImageTextProps } from '../../type.pdf';

export const PdfImageText = ({
  comment,
  type,
  title,
  subtitle,
}: PdfImageTextProps) => (
  <View style={styles.imageContainer}>
    <Text style={styles.title}>{title}</Text>
    <Text style={styles.subtitle}>{subtitle}</Text>
    {type?.map((singleType) => (
      <Text key={`damageType ${singleType}`} style={styles.damageTypes}>
        - {singleType}
      </Text>
    ))}
    <Text>{comment}</Text>
  </View>
);
