import { Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../styles';

export const PdfErrorsRow = ({
  errorCodes,
  offset = 1,
}: {
  errorCodes: string[] | undefined;
  offset?: number;
}) =>
  errorCodes?.length ? (
    <View style={styles.imageRow}>
      {errorCodes.map((code: string, index: number) => (
        <Text
          key={`${index + offset}. ${code}`}
          style={[styles.textResult, styles.dataHeader, styles.errorCodes]}
        >
          {index + offset}. {code}
        </Text>
      ))}
    </View>
  ) : null;
