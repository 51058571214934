import { Link, Text } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../styles';

export const PdfQRCodes = ({ qrCodes }: { qrCodes: string }) => (
  <>
    {qrCodes.startsWith('http') ? (
      <Link style={styles.commentText} src={qrCodes}>
        {qrCodes}
      </Link>
    ) : (
      <Text style={styles.commentText}>{qrCodes}</Text>
    )}
  </>
);
