import { Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../styles';
import { FormattedPart } from '../../type.pdf';

export const PdfParts = ({ parts }: { parts: FormattedPart[] }) => (
  <>
    {parts?.map(({ number, description, quantity }: FormattedPart) => (
      <View style={styles.partPadding}>
        <Text>{number}</Text>
        <Text>{quantity}</Text>
        <Text>{description}</Text>
      </View>
    ))}
  </>
);
