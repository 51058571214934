import ExtendedTranslationMessages from './extended-translation-messages';

const de: ExtendedTranslationMessages = {
  ra: {
    sort: {
      ASC: '',
      DESC: '',
      sort_by: '',
    },
    action: {
      create_item: '',
      move_down: '',
      move_up: '',
      select_all: '',
      select_row: '',
      update: '',
      add_filter: 'Filter hinzufügen',
      add: 'Neu',
      back: 'Zurück',
      bulk_actions:
        'Ein Element ausgewählt |||| %{smart_count} Elemente ausgewählt',
      cancel: 'Abbrechen',
      clear_input_value: 'Eingabe löschen',
      clone: 'Klonen',
      confirm: 'Bestätigen',
      create: 'Erstellen',
      delete: 'Löschen',
      edit: 'Bearbeiten',
      export: 'Exportieren',
      list: 'Liste',
      refresh: 'Aktualisieren',
      remove_filter: 'Filter entfernen',
      remove: 'Entfernen',
      save: 'Speichern',
      search: 'Suchen',
      show: 'Anzeigen',
      sort: 'Sortieren',
      undo: 'Zurücksetzen',
      unselect: 'Abwählen',
      expand: 'Erweitern',
      close: 'Schließen',
      open_menu: 'Menü öffnen',
      close_menu: 'Menü schließen',
    },
    boolean: {
      true: 'Ja',
      false: 'Nein',
      null: '',
    },
    page: {
      create: '%{name} erstellen',
      dashboard: 'Dashboard',
      edit: '%{name} #%{id}',
      error: 'Etwas ist schief gelaufen',
      list: '%{name}',
      loading: 'Lädt',
      not_found: 'Nicht gefunden',
      show: '%{name} #%{id}',
      empty: 'Noch kein %{name}.',
      invite: 'Möchten Sie eine hinzufügen?',
    },
    input: {
      file: {
        upload_several:
          'Zum Hochladen Dateien hineinziehen oder hier klicken, um Dateien auszuwählen.',
        upload_single:
          'Zum Hochladen Datei hineinziehen oder hier klicken, um eine Datei auszuwählen.',
      },
      image: {
        upload_several:
          'Zum Hochladen Bilder hineinziehen oder hier klicken, um Bilder auszuwählen.',
        upload_single:
          'Zum Hochladen Bild hineinziehen oder hier klicken, um eine Bild auszuwählen.',
      },
      references: {
        all_missing:
          'Die zugehörigen Referenzen konnten nicht gefunden werden.',
        many_missing:
          'Mindestens eine der zugehörigen Referenzen scheint nicht mehr verfügbar zu sein.',
        single_missing:
          'Eine zugehörige Referenz scheint nicht mehr verfügbar zu sein.',
      },
      password: {
        toggle_visible: 'Passwort verstecken',
        toggle_hidden: 'Passwort anzeigen',
      },
    },
    message: {
      bulk_update_content: '',
      bulk_update_title: '',
      about: 'Über',
      are_you_sure: 'Bist du sicher?',
      bulk_delete_content:
        'Möchten Sie "%{name}" wirklich löschen? |||| Möchten Sie diese %{smart_count} Elemente wirklich löschen?',
      bulk_delete_title:
        'Lösche %{name} |||| Lösche %{smart_count} %{name} Elemente',
      delete_content: 'Möchten Sie diesen Inhalt wirklich löschen?',
      delete_title: 'Lösche %{name} #%{id}',
      details: 'Details',
      error:
        'Ein Fehler ist aufgetreten und ihre Anfrage konnte nicht abgeschlossen werden.',
      invalid_form:
        'Das Formular ist ungültig. Bitte überprüfen Sie ihre Eingaben.',
      loading: 'Die Seite wird geladen.',
      no: 'Nein',
      not_found: 'Die Seite konnte nicht gefunden werden.',
      yes: 'Ja',
      unsaved_changes:
        'Einige Ihrer Änderungen wurden ncht gespeichert. Sind Sie sich sicher, diese zu verwerfen?',
    },
    navigation: {
      skip_nav: '',
      no_results: 'Keine Resultate gefunden',
      no_more_results: 'Die Seite %{page} enthält keine Inhalte.',
      page_out_of_boundaries:
        'Die Seite %{page} liegt ausserhalb des gültigen Bereichs',
      page_out_from_end: 'Letzte Seite',
      page_out_from_begin: 'Erste Seite',
      page_range_info: '%{offsetBegin}-%{offsetEnd} von %{total}',
      page_rows_per_page: 'Zeilen pro Seite:',
      next: 'Weiter',
      prev: 'Zurück',
    },
    auth: {
      auth_check_error: 'Bitte Einloggen zum Fortfahren',
      user_menu: 'Profil',
      username: 'Nutzername',
      password: 'Passwort',
      sign_in: 'Anmelden',
      sign_in_error: 'Fehler bei der Anmeldung',
      logout: 'Abmelden',
    },
    notification: {
      not_authorized: 'nicht berechtigt',
      updated:
        'Element wurde aktualisiert |||| %{smart_count} Elemente wurden aktualisiert',
      created: 'Element wurde erstellt',
      deleted:
        'Element wurde gelöscht |||| %{smart_count} Elemente wurden gelöscht',
      bad_item: 'Fehlerhaftes Elemente',
      item_doesnt_exist: 'Das Element existiert nicht',
      http_error: 'Fehler beim Kommunizieren mit dem Server',
      data_provider_error:
        'dataProvider-Fehler. Überprüfe die Konsole für mehr Informationen.',
      i18n_error:
        'Die Übersetzungen für die ausgewählte Sprache konnte nicht geladen werden.',
      canceled: 'Aktion abgebrochen',
      logged_out:
        'Ihre Sitzung wurde beendet. Bitte verbinden Sie sich erneut.',
    },
    validation: {
      required: 'Benötigt',
      minLength: 'Muss mindestens %{min} Zeichen lang sein',
      maxLength: 'Darf maximal %{max} Zeichen lang sein',
      minValue: 'Muss mindestens %{min} sein',
      maxValue: 'Muss %{max} oder weniger sein',
      number: 'Muss eine Nummer sein',
      email: 'Muss eine gültige E-Mail sein',
      oneOf: 'Es muss einer sein von: %{options}',
      regex: 'Es muss folgendem regulären Ausdruck entsprechen: %{pattern}',
      phone: 'Muss eine gültige Telefonnummer sein',
    },
  },
  action: {
    archive: 'Archivieren',
    unarchive: 'Wiederherstellen',
    select: 'Auswählen',
    continue_draft: 'Entwurf fortsetzen',
    import: 'Importieren',
    export: 'Exportieren',
    ungroup: 'Gruppierung aufheben',
    resend_password: 'Passwort erneut senden',
    import_success: 'Import erfolgreich',
    import_failure: 'Import fehlgeschlagen',
  },
  app: {
    title: 'Audit App Dashboard',
  },
  auth: {
    no_auth:
      'Sie müssen sich erst einloggen, bevor Sie auf die Seite zugreifen.',
    reset_password_error: 'Neues Passwort konnte nicht gesetzt werden',
    select_department: 'Bitte wählen Sie ein Abteilung aus',
    resend_password_error: 'Passwort konnte nicht erneut gesendet werden',
    password_resent: 'Passwort wurde erneut gesendet',
    invalid_email: 'Ungültige E-Mail Adresse',
    permission_denied: 'Erlaubnis verweigert',
  },
  checkboxes: {
    value: 'Checklistenelement',
    addCheckbox: 'Element hinzufügen',
  },
  'free-input': {
    value: 'Freitext',
    addFreeInput: 'Freitext hinzufügen',
  },
  config: {
    'error-codes': 'Fehlercodes',
    'images-damages': 'Status + Schadensbilder',
    'nested-spec': 'Unterkategorie',
    choose: 'Auswählen',
    damages: 'Schadensbilder',
    images: 'Statusbilder',
    status: 'Daumen Bewertung',
    rating: 'Bewertung',
    parts: 'Liste der Einzelteile',
    checkboxes: 'Checkliste',
    signature: 'Unterschrift',
    tutorial: 'Tutorial',
    'qr-codes': 'QR-Code',
    'status-parts': 'Status + Liste der Einzelteile',
    videos: 'Videos',
    'free-input': 'Freitext',
  },
  damages: {
    max_damages: 'Max. Anzahl an Schäden',
    comment: 'Kommentar',
    type_options: 'Schadenstypen',
  },
  dropdown: {
    accept: 'Kunde akzeptiert',
    notaccept: 'Kunde akzeptiert nicht',
    status: 'Status ausgewählt',
    statushelper: 'Inspektionsreportstatus wählen',
  },
  empty: {
    title: 'Keine Produkte vefugbar!',
    subtitle:
      'Erstellen Sie eine oder gehen Sie zu einer anderen Registerkarte.',
  },
  errors: {
    'inspection-templates': {
      unauthorized:
        'Sie verfügen nicht über die Rechte diese Inspektionsvorlage zu bearbeiten',
      invalid:
        'Das Formular ist nicht gültig. Bitte überprüfen Sie die folgenden Bereiche:',
    },
    inspections: {
      unauthorized:
        'Sie verfügen nicht über die Rechte diesen Inspektionsreport zu bearbeiten',
    },
    machines: {
      unauthorized:
        'Sie verfügen nicht über die Rechte diese Maschine zu bearbeiten',
    },
    users: {
      unauthorized:
        'Sie verfügen nicht über die Rechte diese Rolle hinzuzufügen',
    },
    title: 'Ups',
    subtitle: 'Entschuldigung, etwas ist schief gelaufen',
    back: 'Zurück gehen?',
  },
  icons: {
    accountStar: 'Konto',
    archive: 'Archiv',
    barcode: 'Strichcode',
    barcodeScan: 'Barcode-Scan',
    checkDecagram: 'Aktiv',
    clipboardListOutline: 'Vorlage',
    collapseDown: 'Zusammenklappen',
    collapseRight: 'Zusammenklappen',
    idCard: 'ID',
    qrCode: 'QR-Code',
    tractorVariant: 'Traktor',
    beakerCheck: 'Geprüfte Inspektion',
    brakes: 'Bremsen',
    chain: 'Kette',
    crank: 'Kurbel',
    derailleur: 'Schaltung',
    frame: 'Rahmen',
    pedal: 'Pedale',
    saddle: 'Sitz',
    wheel: 'Felgen',
    accessories: 'Zubehör',
    auger: 'Schnecke',
    cabin: 'Kabine',
    compactor: 'Müllverdichter',
    date: 'Datum',
    electric: 'Elektrisches System',
    engine: 'Dieselmotor',
    heating: 'Heizelement',
    hopper: 'Kübel',
    hours: 'Laufzeit',
    hydraulic: 'Hydraulisches System',
    joint: 'Knickgelenk/Schwenklager',
    last_service: 'Letzte Wartung',
    liquids: 'Flüssigkeiten',
    machine: 'Machine',
    milling: 'Fräswalze',
    next_service: 'Nächste Wartung',
    options: 'Optionen',
    parts: 'Empfohlene Ersatzteile',
    paver: 'Straßenfertiger',
    planer: 'Kaltfräse',
    pressure: 'Druck und Drehzahl',
    recycler: 'Recycler',
    repairs: 'Empfohlene Reperaturen',
    screed: 'Bohle',
    serial_no: 'Seriennummer',
    steering: 'Lenkrad',
    shape: 'Form',
    transfer_box: 'Getriebe',
    textBoxCheckOutline: 'Textfeld',
    tyres: 'Walzen/Reifen/Achsen',
    vibration: 'Vibration',
    visual: 'Visuelles',
    year: 'Jahr',
  },
  icon_alts: {
    accountStar: 'Konto',
    archive: 'Archiv',
    barcode: 'Strichcode',
    barcodeScan: 'Barcode-Scan',
    checkDecagram: 'Aktiv',
    clipboardListOutline: 'Vorlage',
    collapseDown: 'Zusammenklappen',
    collapseRight: 'Zusammenklappen',
    idCard: 'ID',
    qrCode: 'QR-Code',
    tractorVariant: 'Traktor',
    beakerCheck: 'Geprüfte Inspektion',
    brakes: 'Bremsen',
    chain: 'Kette',
    crank: 'Kurbel',
    derailleur: 'Schaltung',
    frame: 'Rahmen',
    pedal: 'Pedale',
    saddle: 'Sitz',
    wheel: 'Felgen',
    accessories: 'Zubehör',
    auger: 'Schnecke',
    cabin: 'Kabine',
    compactor: 'Müllverdichter',
    date: 'Datum',
    electric: 'Elektrisches System',
    engine: 'Dieselmotor',
    heating: 'Heizelement',
    hopper: 'Kübel',
    hours: 'Laufzeit',
    hydraulic: 'Hydraulisches System',
    joint: 'Knickgelenk/Schwenklager',
    last_service: 'Letzte Wartung',
    liquids: 'Flüssigkeiten',
    machine: 'Machine',
    milling: 'Fräswalze',
    next_service: 'Nächste Wartung',
    options: 'Optionen',
    parts: 'Empfohlene Ersatzteile',
    paver: 'Straßenfertiger',
    planer: 'Kaltfräse',
    pressure: 'Druck und Drehzahl',
    recycler: 'Recycler',
    repairs: 'Empfohlene Reperaturen',
    screed: 'Bohle',
    serial_no: 'Seriennummer',
    steering: 'Lenkrad',
    shape: 'Form',
    transfer_box: 'Getriebe',
    textBoxCheckOutline: 'Textfeld',
    tyres: 'Walzen/Reifen/Achsen',
    vibration: 'Vibration',
    visual: 'Visuelles',
    year: 'Jahr',
  },
  images: {
    edit_image: 'Bild editieren',
    no_title: 'Keine Bezeichnung',
    no_subtitle: 'Kein Hinweis',
    subtitle: 'Hinweis',
    title: 'Bezeichnung',
    upload: 'Bild auswählen',
  },
  inspections: {
    basicdata: 'Basisdaten',
    inspectiondata: 'Inspektionsreportdaten',
    fotos: 'Fotos',
    maindata: 'Kopfdaten',
    nodata: 'Keine Daten',
    overview: 'Überblick',
    quickcheck: 'Quickcheck',
    status: {
      checked: 'Geprüft',
      editing: 'In Bearbeitung',
      none: 'Keine',
      offer_accepted: 'Ankauf abgeschlossen',
      offer_sent: 'Angebot an Kunden',
      started: 'Erhalten',
    },
  },
  languages: {
    english: 'Englisch',
    german: 'Deutsch',
  },
  menu: {
    active: 'Aktiv',
    archived: 'Archiviert',
    machines: 'Maschinen',
    inspectionTemplates: 'Inspektionsvorlagen',
    inspections: 'Inspektionsreporte',
    profile: 'Einstellungen',
    logo_alt: 'schmiede.ONE',
    published: 'Veröffentlicht',
    roles: 'Rollen',
    user_management: 'Nutzerverwaltung',
    users: 'Nutzer',
    groups: 'Gruppen',
    departments: 'Abteilungen',
  },
  notification: {
    archived: 'Archiviert',
    unarchived: 'Wiederhergestellt',
    ungrouped: 'Ungruppiert',
    regrouped: 'Gruppe geändert',
  },
  parts: {
    number: 'Artikelnummer',
    description: 'Beschreibung',
    quantity: 'Menge',
    empty: 'Leer',
    addContact: 'E-Mail hinzufügen',
    addPart: 'Teil hinzufügen',
    requestQuote: 'Angebot anfordern',
    toRequestQuote:
      'Um ein Angebot anzufordern, stellen Sie bitte die E-Mail-Adresse des Maschinenkontakts ein',
    error: 'Bei der Angebotsanfrage ist leider ein Fehler aufgetreten',
    success: 'Eine Angebotsanfrage wurde gesendet ',
  },
  profile: {
    profile: 'Profil',
    general: {
      dealer: 'Ihr Händler',
      email: 'E-mail',
      name: 'Frist Name',
      password: 'Passwort',
      password_2: 'Passwort wiederholen',
      phone: 'Telefonnummer',
      region: 'Ihre Region',
      role: 'Ihre Rolle',
      save: 'Speichern',
      surname: 'Familienname',
      upload_picture: 'Bild hochladen',
    },
    placeholders: {
      your_email: 'Ihre E-Mail',
      your_name: 'Ihr Name',
      your_password: 'Ihr Passwort',
      your_password_2: 'Dein Passwort',
      your_phone: 'Ihre Telefonnummer',
      your_surname: 'Ihr Nachname',
    },
  },
  resources: {
    machines: {
      machine: 'Maschine',
      name: 'Maschine |||| Maschinen',
      fields: {
        id: 'ID',
        manufacturer: 'Kategorie',
        category: 'Subkategorie',
        model: 'Inspektionsname',
        inspection_template_id: 'Inspektionsvorlage',
        inspection_details: 'Inspektionsdetails',
        label: 'Label',
        type: 'Art',
        text: 'Text',
        number: 'Nummer',
        date: 'Datum',
        example: 'Beispiel',
        createdAt: 'Erstellt am',
        updatedAt: 'Aktualisiert am',
        contact: 'Kontakt',
        organisation: 'Unternehmen',
        phone: 'Telefon',
        email: 'E-Mail',
        machine_details: 'Maschinedetails',
        header_data: 'Zusatzdaten',
        inspection_fields: 'Inspektionsfelder',
        serials: {
          allowed: 'Zulässige Seriennummers',
          serial: 'Seriennummer',
          min: 'Minimum',
          max: 'Maximal',
        },
        labels: {
          allowed: 'zulässige Etiketten',
          label: 'Label',
        },
      },
      deletion: {
        confirmation: 'Möchten Sie die Maschine Nummer %{id} wirklich löschen?',
        hasInspection:
          'Die Maschine Nummer %{id} kann nicht gelöscht werden, da sie von den folgenden Inspektionen verwendet wird: %{ids}',
      },
    },
    'inspection-templates': {
      deletion: {
        hasInspection:
          'Die Vorlage Nummer %{id} kann nicht gelöscht werden, da sie von den folgenden Inspektionen verwendet wird: %{inspections}',
        hasMachine:
          'Die Vorlage Nummer %{id} kann nicht gelöscht werden, da sie von den folgenden Maschine verwendet wird: %{machines}',
        hasBoth:
          'Die Vorlage Nummer %{id} kann nicht gelöscht werden, da sie von den folgenden Inspektionen verwendet wird: %{inspections} und den folgenden Maschinen:% {machines} ',
        confirmation: 'Möchten Sie die Vorlage Nummer %{id} wirklich löschen?',
      },
      name: 'Inspektionsvorlage |||| Inspektionsvorlagen',
      fields: {
        company: 'Firma',
        config: 'Modul',
        createdAt: 'Erstellt am',
        helper_title: 'Helfer',
        helper: 'Helper',
        helper_type: {
          text: 'Text',
          link: 'Link',
          image: 'Bild',
        },
        helper_text: 'Hilfstext',
        icon: 'Icon',
        id: 'ID',
        label: 'Bezeichnung',
        name: 'Name',
        region: 'Region',
        spec: 'Bausteine',
        sub_spec: 'Unterkategorie',
        title: 'Neuer Baustein',
        type: 'Typ',
        updatedAt: 'Aktualisiert am',
        version: 'Version',
        sub_type: 'Untertyp',
        sub_label: 'Unterbezeichnung',
      },
    },
    inspections: {
      name: 'Inspektionsreport |||| Inspektionsreporte',
      fields: {
        id: 'ID',
        inspection_template_id: 'Inspektionsvorlage',
        serial: 'Seriennummer',
        inspector: 'Inspektor',
        inspection_location: 'Inspektionsort',
        operating_hours: 'Betriebsstunden',
        inspection_date: 'Inspektionsdatum',
        machine_id: 'Maschine',
        assignee: 'Abtretungsempfänger(in)',
        inspection_data: 'Inspektionsdatum',
        region: 'Region',
        company: 'Firma',
        createdAt: 'Erstellt am',
        updatedAt: 'Aktualisiert am',
        label: 'Label',
      },
    },
    users: {
      name: 'Nutzer |||| Nutzer',
      fields: {
        company: 'Firma',
        email: 'E-Mail',
        id: 'ID',
        region: 'Region',
        role: 'Rolle',
      },
    },
    groups: {
      name: 'Gruppe |||| Gruppen',
      fields: {
        id: 'ID',
        serial: 'Serial No.',
      },
      group: 'Gruppe',
    },
    'qr-codes': {
      fields: {
        build_year: 'Baujahr',
        category: 'Kategorie',
        model: 'Modell',
        serial: 'Seriell',
      },
      qr: {
        download: 'Herunterladen',
      },
    },
    departments: {
      department: 'Abteilung',
      default: 'Standard',
    },
  },
  role: {
    centraladmin: 'Zentraladmin',
    dealeradmin: 'Händleradmin',
    dealeruser: 'Händlernutzer',
    devadmin: 'Entwickler-Admin',
    norole: 'Keine Rolle',
    regionadmin: 'Länderadmin',
    regionuser: 'Ländernutzer',
    departmentadmin: 'Abteilungsadmin',
    departmentuser: 'Abteilungsbenutzer',
  },
  specification: {
    appearance: 'Details',
    function: 'Funktion',
    gallery_codes: 'Bilder / Fehlercodes',
    nodata: 'Keine Daten',
    not_available: 'Nicht vorhanden',
    no_damage: 'Keine Beschädigung',
    not_complete: 'Nicht geprüft',
    not_okay: 'Schaden',
    okay: 'In Ordnung',
    parts_needed: 'Teile Gebraucht',
    status: 'Status',
    comment: 'Kommentar',
    tutorial_text: 'Tutorial-Text',
  },
  status: {
    code: 'Produktcode',
    note: 'Note',
    url: 'Url',
  },
  pdf: {
    cumulative_score: 'Kumulative Punktzahl',
    includeImages: 'Bilder einschließen',
    yes: 'Ja',
    no: 'Nein',
    comment: 'Kommentar',
    quality_check: 'Qualitätsprüfung',
    serial: 'Serienummer',
    inspection_date: 'Inspektionsdatum',
    inspection_data: 'Inspektionsdaten',
    inspection_location: 'Betriebsstunden',
    operating_hours: 'Operating hours',
    inspection_id: 'Inspektions ID',
    machine_id: 'Maschinens ID',
    template_name: 'Inspektionsvorlage Name',
    template_id: 'Inspektionsvorlage ID',
    id: 'ID',
    machine_location: 'Maschinenstandort',
    operating_hours_return: 'Betriebstunden (1)',
    operating_hours_service: 'Betriebstunden (2)',
    owner: 'Eigentümer',
    return_date: 'Rückgabedatum',
    salesperson: 'Verkäufer',
    inspection_details: 'Einzelheiten der Inspektion',
    checked: 'Geprüft',
    data_missing: 'Daten fehlen',
    damages: 'Schäden',
    skipped: 'Übersprungen',
    inspection_pictures: 'Bilder',
    loading: 'Laden...',
    report: 'PDF',
    not_available: 'Nicht vorhanden',
    no_damage: 'Keine Beschädigung',
    download: 'PDF Herunterladen',
    close: 'Close',
    imageQuality: 'Bildqualität',
    low: 'Niedrig',
    medium: 'Mittel',
    high: 'Hoch',
    pdf_download_error: 'Herunterladen der PDF-Datei fehlgeschlagen',
    page: 'Seite',
    of: 'von',
  },
  theme: {
    theme: 'Farbschema',
    random: 'Zufällig',
    full_random: 'Voll Zufällig',
    custom: 'Eigenes Farbschema',
    main: 'Hauptfarbe',
    background: 'Hintergrundfarbe',
    apply: 'Anwenden',
    clear: 'Lösche Verlauf',
    dark: 'Dunkel',
    light: 'Hell',
    mono: 'Einfarbig',
    history: 'Verlauf',
  },
  csv_import: {
    dropzone: {
      title: 'CSV Dropzone',
      subtitle:
        'Bitte laden Sie eine CSV-Datei hoch, um die Vorlage zu füllen.',
      csv_reader: 'CSV-Datei hier ablegen oder zum Hochladen klicken.',
    },
    notification: {
      populated: 'Bevölkert',
      imported: 'Importierte% {file}',
    },
    empty: 'Leer',
    tooltip: 'Muss eine ".csv" -Datei sein',
    label: 'Import checklists',
    confirm: 'Bestätigen',
    cancel: 'Abbrechen',
    rows_per_page: 'Zeilen pro Seite',
    of: 'von',
  },
  undefined: '-',
};

export default de;
