import { View } from '@react-pdf/renderer';
import { Machine } from 'pages/inspections/types.inspections';
import React from 'react';
import { styles } from '../styles';
import { PdfDetailRow } from './detail-row';

interface InspectionDetail {
  label: string;
  type: string;
  value: string;
}

interface HeadDatum {
  label: string;
  value: string;
}
interface PdfDetailsProps {
  serial: string;
  operating_hours: string;
  inspectionDetails: InspectionDetail[];
  headData: HeadDatum[];
  machine: Machine;
  translate: (arg: string) => string;
}

export const PdfDetails = ({
  serial,
  operating_hours,
  inspectionDetails,
  headData,
  machine,
  translate,
}: PdfDetailsProps) => (
  <View style={styles.section}>
    {headData.map((headingItem: HeadDatum, headingIndex: number) => (
      <PdfDetailRow
        key={headingItem.label}
        label={translate(`pdf.${headingItem.label}`)}
        value={headingItem.value}
        index={headingIndex}
      />
    ))}
    {inspectionDetails?.map(
      (detailsItem: InspectionDetail, detailIndex: number) => (
        <PdfDetailRow
          key={detailsItem.label}
          label={detailsItem.label}
          value={detailsItem.value}
          index={detailIndex + headData.length}
        />
      ),
    )}
    <PdfDetailRow
      key={'serial no.'}
      label={'serial no.'}
      value={serial || '-'}
      index={headData.length + inspectionDetails.length}
    />
    <PdfDetailRow
      key={'operating_hours'}
      label={translate('resources.inspections.fields.operating_hours')}
      value={operating_hours || '-'}
      index={headData.length + inspectionDetails.length + 1}
    />
    {['contact', 'organisation', 'phone', 'email'].map(
      (key: string, machineIndex: number) => (
        <PdfDetailRow
          key={key}
          label={key}
          value={machine[key] || '-'}
          index={machineIndex + headData.length + inspectionDetails.length + 2}
        />
      ),
    )}
  </View>
);
