import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import React, { useContext } from 'react';
import { useGetOne, useNotify, useTranslate } from 'react-admin';
import {
  DataObject,
  InspectionTemplate,
  Machine,
} from '../../types.inspections';
import { PdfContext } from './components/context/pdf-context';
import { PdfDocument } from './document';
import { formatPdfRecursively } from './utils';
import { RecursivelyFormattedSpecData } from './type.pdf';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  button: {
    width: '100%',
  },
  spinner: {
    marginRight: theme.spacing(1),
  },
}));

interface PdfButtonProps {
  record: DataObject;
  machine: Machine;
  machineName: string;
}

export const LazyDownloadPDFButton = ({
  record,
  machine,
  record: { inspection_template_id },
  machineName,
}: PdfButtonProps) => {
  const classes = useStyles();
  const notify = useNotify();
  const translate = useTranslate();
  const { data: template } = useGetOne<InspectionTemplate>(
    'inspection-templates',
    inspection_template_id,
  );

  const pdfState = useContext(PdfContext);

  const updateRenderingStatus = ({
    loading,
    status,
  }: {
    loading: boolean;
    status?: string;
  }) => pdfState.setState({ ...pdfState, renderingState: { loading, status } });

  const formatPdfData = async () => {
    const result: RecursivelyFormattedSpecData[] = await formatPdfRecursively({
      parentSpec: template?.spec,
      dataObject: record,
      translate,
      displayImages: pdfState.includeImages,
      imageResizingOptions: pdfState.imageResizingOptions,
    });
    const filtered = result?.filter((data) => !!data);
    return filtered;
  };

  const onDownloadClicked = async () => {
    if (!pdfState.renderingState.loading) {
      try {
        updateRenderingStatus({
          loading: true,
          status: translate('pdf.loading'),
        });
        const pdfData = await formatPdfData();
        const doc = (
          <PdfDocument
            translate={translate}
            template={template}
            data={record}
            machine={machine}
            machineName={machineName}
            formattedData={pdfData}
          />
        );

        const asPdf = pdf();
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
        updateRenderingStatus({ loading: false });
        saveAs(blob, 'document.pdf');
      } catch (error) {
        notify('pdf.pdf_download_error', 'error', { autoHideDuration: 2000 });
        updateRenderingStatus({
          loading: false,
          status: translate('pdf.pdf_download_error'),
        });
      }
    }
  };

  return template ? (
    <Box className={classes.container}>
      <Button
        variant="contained"
        onClick={onDownloadClicked}
        className={classes.button}
      >
        {!pdfState.renderingState.loading && translate('pdf.download')}
        {pdfState.renderingState.loading && (
          <>
            <CircularProgress
              color="secondary"
              size={25}
              className={classes.spinner}
            ></CircularProgress>
            <Typography>{pdfState.renderingState.status}</Typography>
          </>
        )}
      </Button>
    </Box>
  ) : (
    <Box className={classes.container}>
      <Button variant="contained" onClick={() => {}} className={classes.button}>
        {translate('pdf.loading')}
      </Button>
    </Box>
  );
};
