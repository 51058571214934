import { Record } from 'ra-core';
import { GroupIdInput } from 'components/group-id-input';
import React from 'react';
import {
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  List,
  ReferenceField,
  ShowButton,
  TextField,
  TextInput,
  useTranslate,
} from 'react-admin';
import {
  ArchiveToggleButton,
  BulkArchiveToggleButton,
} from '../../../components';
import { statuses } from '../show/header/utils';

const StatusField = (props: any) => {
  const { record } = props;
  const status = statuses.find((s) => s.value === record.status);
  const translate = useTranslate();
  return (
    <TextField
      {...props}
      record={{ ...record, status: translate(status?.label ?? '') }}
    />
  );
};

const InspectionFilter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput label="serial" source="serial" alwaysOn />
    </Filter>
  );
};

const InspectionBulkActions = ({ selectedIds, resource }: any) => (
  <BulkArchiveToggleButton
    areArchived={false}
    selectedIds={selectedIds}
    resource={resource}
  />
);

export const InspectionList = (props: object) => {
  const translate = useTranslate();

  return (
    <List
      filter={{ archived: false }}
      filters={<InspectionFilter />}
      bulkActionButtons={<InspectionBulkActions />}
      {...props}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="serial" />
        <FunctionField
          source="group_id"
          label="Group"
          render={(record: Record) => (
            <GroupIdInput record={record} resource={'inspections'} />
          )}
        />
        <ReferenceField
          source="machine_id"
          reference="machines"
          label={translate('resources.machines.fields.manufacturer')}
        >
          <TextField source="manufacturer" />
        </ReferenceField>
        <ReferenceField
          source="machine_id"
          reference="machines"
          label={translate('resources.machines.fields.category')}
        >
          <TextField source="category" />
        </ReferenceField>
        <ReferenceField
          source="machine_id"
          reference="machines"
          label={translate('resources.machines.fields.model')}
        >
          <TextField source="model" />
        </ReferenceField>
        <ReferenceField
          source="inspection_template_id"
          reference="inspection-templates"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="department_id"
          label={translate('resources.departments.department')}
          reference="departments"
          emptyText={translate('resources.departments.default')}
        >
          <TextField source="name" />
        </ReferenceField>

        <DateField source="createdAt" sortBy="created_at" />
        <StatusField source="status" />
        <ShowButton />
        <ArchiveToggleButton />
      </Datagrid>
    </List>
  );
};
