import { Document, Font, Page } from '@react-pdf/renderer';
import React from 'react';
import schmiedeLogo from '../../../../img/Schmiede_logo_gross.jpg';
import {
  DataObject,
  InspectionTemplate,
  Machine,
} from '../../types.inspections';
import { PdfData, PdfDetails, PdfHeader, PdfPageNumbers } from './components';
import { styles } from './components/styles';
import { RecursivelyFormattedSpecData } from './type.pdf';
import { createHeadData } from './utils';

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});

interface PdfDocumentProps {
  translate: (arg1: string) => string;
  machine: Machine;
  machineName: string;
  data: DataObject;
  template: InspectionTemplate;
  formattedData: RecursivelyFormattedSpecData[];
}

export const PdfDocument = ({
  translate,
  machine,
  machineName,
  data,
  template,
  formattedData,
}: PdfDocumentProps) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <PdfPageNumbers />
      <PdfHeader
        header={translate('pdf.quality_check')}
        subheader={machineName}
        image={schmiedeLogo}
      />
      <PdfDetails
        inspectionDetails={data?.inspection_details}
        headData={createHeadData(data, template)}
        machine={machine}
        serial={data?.serial}
        operating_hours={data?.operating_hours}
        translate={translate}
      />
    </Page>
    <Page style={styles.page}>
      <PdfPageNumbers />
      <PdfHeader header={translate('pdf.inspection_data')} />
      <PdfData pdfData={formattedData} />
    </Page>
  </Document>
);
