import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { styles } from '../styles';

interface PdfDetailRowProps {
  label: string;
  value: string;
  index: number;
}

export const PdfDetailRow = ({ label, value, index }: PdfDetailRowProps) => (
  <View
    key={`${index}-detail-${label}`}
    style={[
      index % 2 === 0 ? styles.dataTableGrey : styles.dataTableWhite,
      styles.inspectionText,
    ]}
  >
    <Text style={styles.textHeader}>{label}:</Text>
    <Text style={styles.textElement}>{value}</Text>
  </View>
);
