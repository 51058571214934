import { Text, View } from '@react-pdf/renderer';
import React from 'react';
import { PdfCheckboxes } from './checkboxes';
import { PdfErrorsRow } from './errors-row';
import { PdfParts } from './parts';
import { PdfStatusInfo } from './status-info';
import { PdfQRCodes } from './qr-codes';
import { FormattedText } from '../../type.pdf';
import { styles } from '../styles';
import { PdfFreeInput } from './free-input';

export const PdfText = ({
  header,
  status,
  specStatus,
  comment,
  errors,
  statusInfo,
  parts,
  checkboxes,
  qrCodes,
  freeInputPairs,
}: FormattedText) => (
  <>
    <View
      key={`${header}-${status}`}
      style={[styles.dataTableGrey, styles.resultsRow, styles.dataHeader]}
    >
      <Text style={styles.textHeader}>{header}</Text>
      {status && specStatus && (
        <View
          style={styles.statusContainer}
        >
          <View
            style={[
              styles.statusMarkerBase,
              specStatus === 'OKAY'
                ? styles.backgroundGreen
                : specStatus === 'NOT_AVAILABLE'
                ? styles.borderGreen
                : specStatus === 'NOT_CHECKED'
                ? styles.backgroundYellow
                : styles.backgroundRed,
            ]}
          />
          <Text style={styles.textStatus}>{status}</Text>
        </View>
      )}
    </View>
    {comment && <Text style={styles.commentText}> - {comment}</Text>}
    {statusInfo && <PdfStatusInfo statusInfo={statusInfo} />}
    {parts && <PdfParts parts={parts} />}
    {checkboxes && <PdfCheckboxes checkboxes={checkboxes} />}
    {qrCodes && <PdfQRCodes qrCodes={qrCodes} />}
    {errors && errors?.length > 5 ? (
      <>
        <PdfErrorsRow errorCodes={errors.slice(0, 5)} />
        <PdfErrorsRow errorCodes={errors.slice(5, errors.length)} offset={6} />
      </>
    ) : (
      <PdfErrorsRow errorCodes={errors} />
    )}
    {freeInputPairs && <PdfFreeInput freeInputPairs={freeInputPairs} />}
  </>
);
