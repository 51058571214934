import React from 'react';
import {
  FormattedSpecData,
  RecursivelyFormattedSpecData,
} from '../../type.pdf';
import { PdfResults } from './results';

export const PdfData = ({
  pdfData,
}: {
  pdfData: RecursivelyFormattedSpecData[];
}) => (
  <>
    {pdfData.map((data: RecursivelyFormattedSpecData, index: number) =>
      Array.isArray(data) ? (
        data
          .filter((subData) => !!subData)
          .map((subData, subIndex: number) => (
            <PdfResults
              key={`${index}-${subIndex}`}
              {...(subData as FormattedSpecData)}
            />
          ))
      ) : (
        <PdfResults key={`${index}`} {...(data as FormattedSpecData)} />
      ),
    )}
  </>
);
